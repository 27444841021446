/* General form styling */
.form-container {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    height: 100%;
  }
  
  .form-container label {
    display: block;
    margin-bottom: 10px;
  }
  
  .form-container label span {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-container input[type="text"],
  .form-container input[type="email"],
  .form-container textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-container textarea {
    height: 100px;
    resize: vertical; /* Allows the user to vertically resize the textarea (not horizontally) */
  }
  
  .form-container button {
    background-color: #0056b3;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .form-container button:hover {
    background-color: #004494;
  }
  
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-pulse infinite 10s linear;
  }
}

.announcement {
  position: absolute;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.announcement-box {
  top: 0;
  background-color: #ffa600;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  width: fit-content;
  z-index: 1000;
  border-radius: 0 0 10px 10px;
  border-left: clamp(6px, 0.5vw, 10px) solid transparent;
  border-right: clamp(6px, 0.5vw, 10px) solid transparent;
  border-top: clamp(6px, 0.5vw, 10px) solid #ff0000;
}

.announcement-section {
  padding: 50px 0;
  background-color: #f4f4f4; /* Light grey background to match other sections */
  margin: 20px 0;
}

.announcement-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  background-color: #ffffff; /* White background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.announcement-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .announcement-section {
    padding: 30px 15px;
  }
  
  .announcement-container {
    padding: 15px;
  }
}

.trapezoid {
  background-color: #007BFF;
  color: white;
  text-align: center;
  margin: 0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  padding: 0.5vw; /* Responsive padding */
  font-size: calc(0.5vw + 0.5vh + 0.5vmin); /* Responsive font size based on viewport */
  border-radius: 0 0 100px 100px;
}

@media (max-width: 768px) {
  .trapezoid {
    border-radius: 0 0 50px 50px;
  }
}

.trapezoid p {
  margin: 0;
  padding: 1px 50px; 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.App-header p {
  padding: 50px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .App-header {
    background-attachment: scroll;
  }
}

.chevron {
  position: absolute;
  bottom: 30px;
  animation: bounce 1s infinite;
}

.chevron svg {
  display: block;
}

@keyframes bounce {
  0%,to {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(.8,0,1,1)
  }

  50% {
      transform: none;
      animation-timing-function: cubic-bezier(0,0,.2,1)
  }
}


.App-link {
  color: #61dafb;
}


@keyframes App-logo-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.container {
  margin: auto;
}

.location-section {
  display: grid;
  grid-template-columns: repeat(13, minmax(0, 1fr));
  padding: 10px 40px 20px 40px;
  margin: auto;
  border-radius: 8px;
  border: 0 solid;
  box-sizing: content-box;
  max-width: 1420px;
  margin-top: 40px;
}

@media (min-width: 980px) {
  .location-section {
    margin-top: 50px;
  }
}

.map {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: span 13 / span 13;
}

@media (min-width: 980px) {
  .map {
    grid-column: span 6 / span 6;
    grid-column-start: 1;
    margin-bottom: 0;
  }
}
@media (min-width: 1280px) {
.map {
    grid-column: span 5 / span 5;
    grid-column-start: 2;
}
}

.map-image {
  display: block;
  width: 100%; /* Ensure the image covers the container width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
}

.map-marker {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -100%);
}

.marker-text {
  font-size: clamp(8px, 1.5vw, 16px);
  color: #ff0000;
}

.marker-arrow {
  width: 0;
  height: 0;
  border-left: clamp(6px, 0.5vw, 10px) solid transparent;
  border-right: clamp(6px, 0.5vw, 10px) solid transparent;
  border-top: clamp(6px, 0.5vw, 10px) solid #ff0000;
}

.map-info {
  position: relative;
  grid-column: span 13 / span 13;
}


@media (min-width: 1280px) {
  .map-info {
    grid-column: span 5 / span 5;
    grid-column-start: span 5;
    grid-column-end: span 5;
  }
}
@media (min-width: 1280px) {
.map-info {
  grid-column-start: 8;
}
}
@media (min-width: 980px) {
.map-info {
  padding-left: 0;
  padding-right: 0;
}
}
@media (min-width: 980px) {
.map-info {
  height: 100%;
}
}
@media (min-width: 980px) {
  .map-info {
    grid-column: span 5 / span 5;
  }
}
@media (min-width: 980px) {
.map-info {
  grid-column-start: 8;
}
}

.map-info h1 {
  color: #333; /* Dark grey color for the heading */
  font-family: 'Arial', sans-serif; /* Font style for the heading */
}

.map-info p {
  color: #666; /* Medium grey color for the paragraph */
  font-family: 'Arial', sans-serif; /* Font style for the paragraph */
  line-height: 1.6; /* Line height for better readability */
}

.image-marquee-wrapper {
  margin: 60px 0;
  overflow: hidden;  /* This should allow the inner content to overflow */
  width: 100%;       /* Ensure this is constraining the marquee as expected */
}

.image-marquee {
  display: flex;
  white-space: nowrap;
  overflow-x: hidden; /* Temporarily set this to scroll to see the scrollbar */
}
@media (min-width: 1280px) {
  .image-marquee {
    max-height: 700px;
  }
}

.image-marquee img {
  width: calc(100vw* .420278);
  height: 100%;
  margin-right: 20px;
  border-radius: 16px;
}

.mission-section {
  background-color: #f4f4f4; /* Light grey background */
  padding: 20px; /* Padding around the content */
  margin: 20px; /* Margin around the section */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.mission-section h1 {
  color: #333; /* Dark grey color for the heading */
  font-family: 'Arial', sans-serif; /* Font style for the heading */
}

.mission-section p {
  color: #666; /* Medium grey color for the paragraph */
  font-family: 'Arial', sans-serif; /* Font style for the paragraph */
  line-height: 1.6; /* Line height for better readability */
}

.amenities-section {
  background-color: #ffffff; /* White background */
  padding: 20px;
  margin: 20px 0;
}

@media (min-width: 1280px) {
  .amenities-section {
    padding: 20px 25vw;
  }
}

.amenities-boxes {
  display: flex;
}

.amenities-box {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  flex: 1;
}

.amenities-box h1 {
  font-size: 1.2rem;
}

.amenities-section h1 {
  color: #2c3e50;
  font-family: 'Arial', sans-serif;
}

.amenities-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.amenities-section ul li {
  font-size: 18px;
  color: #555;
  padding: 10px 0;
  border-bottom: 1px solid #777;
}

.amenities-section ul li:last-child {
  border-bottom: none;
}

@media (max-width: 768px) {
  .amenities-section {
      padding: 15px;
  }

  .amenities-section h1 {
      font-size: 20px;
  }

  .amenities-section ul li {
      font-size: 16px;
      padding: 8px 0;
  }
}

.contact-section {
  display: grid;
  grid-template-columns: repeat(13, minmax(0, 1fr));
  padding: 10px 40px 20px 40px;
  box-sizing: content-box;
  max-width: 100%;
  margin: 40px;
}

@media (min-width: 980px) {
  .contact-section {
    margin-top: 50px;
  }
}

.contact-info {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  grid-column: span 12 / span 12;
}

@media (min-width: 980px) {
  .contact-info {
    grid-column: span 6 / span 6;
    grid-column-start: 1;
    margin-bottom: 0;
  }
}
@media (min-width: 1280px) {
  .contact-info {
      grid-column: span 5 / span 5;
      grid-column-start: 2;
  }
}

.form-container {
  position: relative;
  grid-column: span 13 / span 13;
}


@media (min-width: 1280px) {
  .form-container {
    grid-column: span 4 / span 4;
    grid-column-start: span 4;
    grid-column-end: span 4;
  }
}
@media (min-width: 1280px) {
  .form-container {
    grid-column-start: 8;
  }
}
@media (min-width: 980px) {
  .form-container {
    padding-left: 0;
    padding-right: 0;
    height: 100%;
    grid-column: span 5 / span 5;
    grid-column-start: 8;
  }
}

#our-team {
  padding: 50px 20%;
  text-align: center;
}

#our-team h2 {
  color: #333;
  margin-bottom: 30px;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.team-member {
  padding: 15px;
  text-align: center;
}

.team-member img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.team-member h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 5px;
}

.team-member p {
  font-size: 16px;
  color: #666;
}

@media (max-width: 980px) {
  #our-team {
    padding: 50px 10%;
  }
  .team-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
  }
}

.history-section {
  background-color: #eef2f7; /* A slightly different light grey background for distinction */
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.history-section h1 {
  color: #3a506b; /* A blue-grey color for the heading */
  font-family: 'Arial', sans-serif;
}

.history-section ul {
  list-style-type: none; /* No bullets for a clean look */
  padding: 0;
}

.history-section li p {
  background-color: #fff; /* White background for each paragraph */
  border: 1px solid #ccc; /* Light grey border */
  padding: 8px;
  margin: 5px 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

#main-footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.footer-section h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.footer-section p,
.footer-section a {
  color: #bbb;
  font-size: 16px;
  text-decoration: none;
}

.footer-section a:hover {
  color: #fff;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 8px;
}

.footer-bottom {
  background-color: #222;
  padding: 10px 0;
  font-size: 14px;
  color: #aaa;
}

@media (max-width: 768px) {
  .footer-content {
      flex-direction: column;
      align-items: center;
  }
  
  .footer-section {
      margin: 20px 0;
  }
}

.cookie-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.3s ease;
}

.cookie-icon:hover {
  transform: scale(1.1);
}

.cookie-bubble {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.cookie-consent-container {
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}

.cookie-consent-content {
  margin-bottom: 10px;
}

.cookie-consent-content p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.cookie-consent-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}